import React from 'react';
import styled from 'styled-components';

const RootDiv = styled.div`
  font-family: 'Nanum Myeongjo', serif;
`;

const FullScreen = styled.div`
  background-image: linear-gradient(
    rgb(230, 179, 199),
    rgb(236, 194, 208),
    rgb(230, 179, 199)
  );
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Header = styled.header`
  align-items: center;
  color: white;
  display: flex;
  font-size: 2em;
  font-weight: 600;
  height: 60px;
  padding: 20px;
`;

const Body = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: -150px;
  text-align: center;
`;

const Tagline = styled.div`
  font-size: 5em;
  font-weight: 700;
`;

const SubTagline = styled.div`
  font-size: 3em;
  font-weight: 300;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledIcon = styled.img`
  margin-right: 20px;
  width: 40px;
`;

function App() {
  return (
    <RootDiv>
      <FullScreen>
        <Header>
          <StyledIcon src="uterus_white.png" />
          Dr. Katie Jones, MD
        </Header>
        <Body>
          <TextContainer>
            <Tagline>OB/GYN</Tagline>
            <SubTagline>Bloomington, IN</SubTagline>
          </TextContainer>
        </Body>
      </FullScreen>
    </RootDiv>
  );
}

export default App;
